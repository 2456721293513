import {Dispatch} from "redux";
import {appLink, IFullOfferDetailParams, parseInvestmentSlugToObject, parseOfferSlugToObject, parseProvidedObjectToSlug} from "@web2/gh_routes";

import {redirectOrEnable404ResponseState} from "../../../app/actions/page_404_actions";
import {IOfferDetailResponse} from "../../../app/interfaces/response/offer_detail";
import {apiLink} from "../../../app/routes/api_link";
import {IRouteState} from "../../../app/routes/data_fetcher/create_app_path_data_fetcher";
import {IServices} from "../../../app/services/IServices";
import {isServer} from "../../../app/utils/read_environment_variables";
import {createRequestActionTypes} from "../../../app/utils/request_response_utils/factories/create_request_action_types";
import {getRequest} from "../../../app/utils/request_response_utils/request";
import {catch404, catchAbortedError} from "../../../app/utils/request_response_utils/response_error";
import {enable301ResponseState, enable302ResponseState} from "../../../app/utils/request_response_utils/response_state/response_state_actions";
import {getOfferListUrl} from "../../list/url_utils/get_offer_list_url";
import {DealTypeSlug, OfferDealType, OfferMarketType, OfferType} from "../../utils/constants_offer";
import {translateOfferType} from "../../utils/utils";

export const fetchOfferTypes = createRequestActionTypes({view: "Offer", type: "GET", name: "OfferDetail"});

export const fetchOfferAtRoute =
    (services: Partial<IServices>, route: IRouteState<IFullOfferDetailParams>) =>
    (dispatch: Dispatch): Promise<IOfferDetailResponse | boolean> => {
        dispatch({type: fetchOfferTypes.start});
        const url = apiLink.offers.detail.base({})({offerId: route.params.offerId});

        return getRequest(services, url, "fetchOfferAtRoute")
            .then(async (json: IOfferDetailResponse) => {
                if (isServer) {
                    if (json.is_redirected) {
                        const redirectUrl = getRegionUrl(json);
                        dispatch(enable302ResponseState(redirectUrl));
                        return Promise.resolve(false);
                    }

                    if (!json.is_active && json.market_type === OfferMarketType.AFTERMARKET) {
                        const redirectUrl = getRegionUrl(json);
                        dispatch(enable301ResponseState(redirectUrl));
                        return Promise.resolve(false);
                    }

                    if (!json.is_active && json.market_type === OfferMarketType.PRIMARY_MARKET && json.investment) {
                        const isInvestmentActive = json.investment.is_active;
                        const redirectUrl = isInvestmentActive
                            ? appLink.investment.detail(parseInvestmentSlugToObject(json.investment.slug))
                            : getRegionUrl(json);
                        dispatch(enable301ResponseState(redirectUrl));
                        return Promise.resolve(false);
                    }
                }

                // User can access any offer by modyfing only offerId in offer slug. If he does so we want to 301 him to correct slug.
                const providedSlug = parseProvidedObjectToSlug({id: route.params.offerId, slug: route.params.offerSlug});

                if (isServer && providedSlug !== json.slug) {
                    const {offerId, offerSlug} = parseOfferSlugToObject(json.slug);
                    dispatch(enable301ResponseState(appLink.fullOffer.detail.base({offerId, offerSlug})));
                    return false;
                }

                dispatch({type: fetchOfferTypes.success, result: json});

                return json;
            })
            .catch(catchAbortedError(() => false))
            .catch(
                catch404(async () => {
                    await dispatch(redirectOrEnable404ResponseState(services, route.pathname));
                    return false;
                })
            );
    };

/**
 * returns url to offer list in region, based on offer detail response
 * @param json - offer detail response
 */
const getRegionUrl = (json: IOfferDetailResponse) => {
    const locationSlug = json.property.location?.slug;
    const [location, subLocation, subSubLocation] = locationSlug ? locationSlug.split("/") : [undefined, undefined, undefined];

    const offerType = translateOfferType(json.property.type, OfferType);
    const isLot = offerType === OfferType.lot;

    const type = json.deal_type === OfferDealType.RENT ? DealTypeSlug.RENT : DealTypeSlug.SELL;

    return getOfferListUrl({
        offerType,
        location,
        subLocation,
        subSubLocation,
        ...(isLot ? {} : {type})
    });
};
